<template>
  <header class="flex justify-between items-center w-full">
    <div class="w-2/12">
      <img
        :src="require('@/assets/logo-region-tacna.png')"
        alt="LOGO GOBIERNO REGIONAL DE TACNA"
        class="w-40"
      />
    </div>

    <div class="w-8/12 text-center px-6 font-semibold">
      <h1 class="mb-1">GOBIERNO REGIONAL DE TACNA</h1>
      <h1 class="text-xs text-gray-400">
        SISTEMA DE INFORMACIÓN INTEGRADO DE VIGILANCIA NUTRICIONAL REGIONAL
      </h1>
    </div>

    <div class="w-2/12">
      <img
        :src="require('@/assets/logo-contra-anemia-black.png')"
        alt="Logo juntos contra la anemia"
        class="w-16"
      />
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
