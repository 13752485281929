import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAllProvincia: (anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_provincia",
          // value: "web_data_reporte_nutricional_provincia_v2",
          value: "web_seg_select_anemia_v2_provincia",
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  
  getAllProvinciaGestanteAnemia: (anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_reporte_padron_provincia_gestantes_anemia",
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllDistrito: (provincia, anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_distrito",
          // value: "web_data_reporte_nutricional_distrito_V2",
          value: "web_seg_select_anemia_v2_distrito",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllDistritoGestanteAnemia: (provincia , anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_anemia_v2_distrito_gestante",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllEstablecimiento: (provincia, distrito, anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_eess",
          // value: "web_data_reporte_nutricional_eess_v2",
          value: "web_seg_select_anemia_v2_establecimiento",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        }
      ]
    };

    console.log(request);

    return post(API_SERVICE_QUERY, request);
  },

  getAllEstablecimientoGestanteAnemia: (provincia, distrito, anio) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          // value: "web_data_reporte_nutricional_eess",
          // value: "web_data_reporte_nutricional_eess_v2",
          value: "web_seg_select_anemia_v2_establecimiento_gestante",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@ANIO",
          value: `%${anio}%`,
          type: "string"
        }
      ]
    };

    console.log(request);

    return post(API_SERVICE_QUERY, request);
  },

  getAllEstablecimientoReporte: (provincia, distrito, tipo) => {
    var buscar = tipo.length > 1 ? `${tipo}` : `%%`;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_graficas_nutricional_eess_v2",
          type: "string"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@TIPO",
          value: buscar,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
