// ------- breadcrumbs --------
export const breadcrumbs = [
  {
    path: "/menu",
    breadcrumbName: "Nutricional"
  },
  {
    // path: "/nutricion",
    path: "/nutricional/graficas",
    breadcrumbName: "Nutricional gráficas"
  }
];

/**
 * graficoProvincia
 * =========================
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=bar1
 */
export const optionGrafico = (provincias = [], numerador = [], denominador = [], titulo = "") => {
  return {
    title: {
      text: titulo,
      left: "center"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      }
    },
    legend: {
      data: ["Numerador", "Denominador"],
      top: 25
    },
    // label: {
    //   show: true,
    //   position: "right"
    // },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: "category",
      data: provincias
    },
    series: [
      {
        name: "Numerador",
        type: "bar",
        data: numerador
      },
      {
        name: "Denominador",
        type: "bar",
        data: denominador
      }
    ]
  };
};

import { semaforoMapaPorcentaje } from "@/utils";

export const generaGraficoBarra = (provincias = [], porcentaje = [], coloresSemaforo) => {
  return {
    tooltip: {
      trigger: "axis",
      formatter: "{a} <br/>{b} : {c} %",
      axisPointer: {
        type: "shadow"
      }
    },
    label: {
      show: true,
      position: "right",
      fontWeight: "bold"
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.05]
    },
    yAxis: {
      type: "",
      data: provincias
    },
    series: [
      {
        name: "Porcentaje",
        type: "bar",
        itemStyle: {
          color: function (param) {
            return semaforoMapaPorcentaje(coloresSemaforo, param.data);
          }
        },
        data: porcentaje,
        markLine: {
          data: [{ type: "average", name: "Avg" }],
          lineStyle: {
            color: "#000"
          }
        }
      }
    ]
  };
};
