import { post } from "./base";

const API_PUBLIC = "/api/Prueba/V1/query";
const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getInfo(idubigeo) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_dawh_coordenada",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "idubigeo",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: idubigeo,
          type: "int"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getDistrito(idubigeo) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_ubigeo",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "idubigeoinei",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: idubigeo,
          type: "int"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getPacientes() {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_paciente_mapa",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getMapaAnemia() {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_anemia",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getSemaforoMapa() {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_semaforo_mapa",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getSemaforoMapaPublic() {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_semaforo_mapa",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },

  getSemaforoMapaPublicv2(IDINDICADOR) {
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value: "sp_obtener_parametro_indicador",
          type: "string"
        },
        {
          name: "@IDINDICADOR",
          value: IDINDICADOR,
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  }
};
